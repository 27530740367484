import Vue from "vue";
import App from "./App.vue";
//引入全局API
import api from "./api";

// 公共样式
import "@/styles/common.scss";
// 引入axios
import axios from "axios";
//element-ui 组件
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
// 路由
import router from "./router/index.js";
// vuex
import store from "./store/index";
// 公共样式
import "@/styles/common.scss";
// 特殊字体
import "./assets/fonts/fonts.css";
// 字体图标
import "./assets/iconfont/iconfont.css";
// echarts
import * as echarts from "echarts";
Vue.prototype.$echarts = echarts;
// 复制
import VueClipboard from "vue-clipboard2";
// dayjs
var dayjs = require("dayjs");
Vue.prototype.dayjs = dayjs;
// 腾讯云
import "tcplayer.js/dist/tcplayer.min.css";
// apis地址
import baseURL from "./api/baseUrl.js";
// 新开窗口
import { openNewWindow } from "./utils/global.js";
Vue.prototype.$openNewWindow = function (routeName, params) {
  openNewWindow(this.$router, routeName, params);
};
// 球队logo
import getTeamLogo from "./utils/teamLogoLoding";

// video视频播放
import videojs from "video.js";
import "video.js/dist/video-js.css";
Vue.prototype.$video = videojs;
// 轮播组件
// import VueAwesomeSwiper from "vue-awesome-swiper";
// import "swiper/css/swiper.css";

//Vue实例挂载axios
Vue.prototype.$axios = axios;
Vue.prototype.$api = api;
Vue.prototype.$getTeamLogo = getTeamLogo;
Vue.prototype.$baseURL = baseURL;
Vue.use(VueClipboard);
Vue.use(ElementUI);
Vue.config.productionTip = false;

// 全局挂载
// Vue.prototype.$imgUrl = "api1.iqty.com"; // 测试 环境
Vue.prototype.$imgUrl = "https://cdn.kuqiu.cn"; // 正式 环境

// 空状态设置
import emptyState from "@/utils/emptyState.vue";
Vue.component("emptyState", emptyState);

router.afterEach((to, from, next) => {
  // vue页面跳转的时候，显示在顶部
  window.scrollTo(0, 0);
  // 百度统计
  // setTimeout(() => {
  //   var _hmt = _hmt || [];
  //   window._hmt = _hmt;
  //   (function () {
  //     //每次执行前，先移除上次插入的代码
  //     document.getElementById("baidu_tj") &&
  //       document.getElementById("baidu_tj").remove();
  //     var hm = document.createElement("script");
  //     hm.src = "https://hm.baidu.com/hm.js?13a2fc423704b5c408f427df2f58f3d0";
  //     hm.id = "baidu_tj";
  //     var s = document.getElementsByTagName("script")[0];
  //     s.parentNode.insertBefore(hm, s);
  //   })();
  // }, 0);
});

// vue页面跳转的时候，显示在顶部
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
});
// 加入百度统计
router.beforeEach((to, from, next) => {
  if (to.path) {
    if (window._hmt) {
      window._hmt.push(["_trackPageview", "/#" + to.fullPath]);
    }
  }

  next();
});
// 球队跳转
Vue.directive("teamto", {
  bind: function (el, binding, vnode) {
    const handleClick = () => {
      sessionStorage.setItem(
        "teamData",
        JSON.stringify({
          teamName: el.textContent,
          teamId: binding.value.teamId,
          competitionId: binding.value.competitionId,
          type: binding.value.type,
        })
      );
      vnode.context.$openNewWindow("teamdata", {
        teamName: el.textContent,
        teamId: binding.value.teamId,
        competitionId: binding.value.competitionId,
        type: binding.value.type,
      });
    };
    el.addEventListener("click", handleClick);
  },
  unbind: function (el) {
    el.removeEventListener("click", el._handleClick);
  },
});

// 全局定义篮球状态吗
Vue.filter("matchStatus", function (value) {
  const statusMap = {
    0: "比赛异常，说明：暂未判断具体原因的异常比赛，可能但不限于：腰斩、取消等等，建议隐藏处理",
    1: "未开赛",
    2: "第一节",
    3: "第一节完",
    4: "第二节",
    5: "第二节完",
    6: "第三节",
    7: "第三节完",
    8: "第四节",
    9: "加时",
    10: "完场",
    11: "中断",
    12: "取消",
    13: "延期",
    14: "腰斩",
    15: "待定",
  };
  return statusMap[value] || "";
});
//收藏指令
Vue.directive("favourite", {
  bind(el, binding, vnode) {
    const { id, isFavourite, type } = binding.value;
    const api = isFavourite ? "unfollow_match" : "follow_match";
    el.addEventListener("click", function () {
      const that = vnode.context;
      that.$api.foot[api]({ match_id: id, match_type: type })
        .then((res) => {
          // 根据服务器响应来更新视图和数据
          that.$emit("my-collect", binding.value);
        })
        .catch((error) => console.log(error));
    });
  },
});

// // 热门数据跳转全部
// Vue.directive("recommendationsTo", function (el, bind, vnode) {
//   el.addEventListener("click", () => {
//     vnode.context.$openNewWindow("recommendations", {
//       teamName: el.textContent,
//     });
//   });
// });
// 热门数据跳转详情
Vue.directive("broadcastTo", function (el, binding, vnode) {
  let that = vnode.context;
  el.addEventListener("click", () => {
    that.$router.push({
      name: "broadcast",
      params: {
        teamName: that,
        ball: binding.value.ball,
        redteamName: binding.value.redteamName,
        blueteamName: binding.value.blueteamName,
        is_playing: binding.value.is_playing,
        matchId: binding.value.match_id,
      },
    });
    console.log("打印click", binding.value);
  });
});

new Vue({
  render: (h) => h(App),
  router,
  store,
}).$mount("#app");
